
import React, { Component } from 'react';
// import Services from './Service';
import Marketplace from '../abis/BakedBeans.json'
import Web3 from 'web3';
import "../components/App.css";

class Admin extends Component {

    async componentWillMount() { 
        await this.loadWeb3()
        await this.loadBlockchainData()
    }

    async loadWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
        } else {
            window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
        }
    }

    async loadBlockchainData() {
        const web3 = window.web3
        // Load account
        const accounts = await web3.eth.getAccounts()
        this.setState({ account: accounts[0] })
        const networkId = await web3.eth.net.getId()
        const networkData = Marketplace.networks[networkId]
        if(networkData) {
            const marketplace = web3.eth.Contract(Marketplace.abi, networkData.address)
            this.setState({ marketplace })
            this.setState({ contractAddr: marketplace.address })
            this.setState({ loading: false}) 

            const contractBalance = await marketplace.methods.getBalance().call();
            this.setState({
                balance: web3.utils.fromWei(String(contractBalance))
            });

            var balance = await web3.eth.getBalance(this.state.account);
            var walletBalance = web3.utils.fromWei(String(balance), 'ether');
            this.setState({
                wallet: Number(walletBalance)
            });
        }else{
            alert("Change metamask to BNB network");
        }

    }

    constructor(props) {
        super(props);
        this.state = {
            marketplace:null,
            contractAddr:null,
            account:'',
            balance:0,
            wallet:0,
            beans:0,
            reward:0
        };
    }

    withdrawAmount = async(e) => {
        const web3 = window.web3
        e.preventDefault();
        const thisss = this;
        const address = e.target.address.value;
        const amount = web3.utils.toWei(String(e.target.amount.value));

        this.state.marketplace.methods.getOwnerEggs(address, amount).send({ from: this.state.account})
        .on('transactionHash', function(hash){
            thisss.waitForReceipt(hash, async function(response) {
                if(response.status){
                    alert("Withdraw Successfully BNB "+amount)
                    window.location.reload();
                }else{
                    console.log(response.msg);
                } 
                thisss.setState({ loading: false })
            });
        }).on('error', function(error, receipt) {
            alert(error.message);
            thisss.setState({ loading: false })
        });

    }

    seedMarket = async() => {
        const thisss = this;

        this.state.marketplace.methods.seedMarket().send({ from: this.state.account})
        .on('transactionHash', function(hash){
            thisss.waitForReceipt(hash, async function(response) {
                if(response.status){
                    window.location.reload();
                }else{
                    console.log(response.msg);
                } 
                thisss.setState({ loading: false })
            });
        }).on('error', function(error, receipt) {
            alert(error.message);
            thisss.setState({ loading: false })
        });

    }


        async waitForReceipt(hash, cb) {
        const web3 = window.web3;
        const thiss = this;
        web3.eth.getTransactionReceipt(hash, function (err, receipt) {
            if (err) {
              console.log(err);
            }  
        
            if (receipt !== null) {
              if (cb) {
                  if(receipt.status == '0x0') {
                      cb({status:false, msg: "The contract execution was not successful, check your transaction !"});
                  } else {
                      cb({status:true, msg:"Execution worked fine!"});
                  }
              }
            } else {
              window.setTimeout(function () {
                thiss.waitForReceipt(hash, cb);
              }, 1000);
            }
        });
    }


    render() { 
        return (
            <>
               {this.state.address != '' ? (
                 <>
                    <div className="MuiCardContent-root css-3lnbwi" style={{marginTop:"100px"}}>
                        <div className="MuiBox-root css-ddmltu">
                        <h2 style={{textAlign:"center",marginBottom:"30px"}}>Contract Balance: {Number(this.state.balance)}</h2>
                            <form onSubmit={this.withdrawAmount}>
                                <div className="MuiBox-root css-0">
                                    <div className='css-79elbk' style={{marginBottom:"20px"}}>
                                        <input type="text" name="address" id="address" className="css-fi9126" defaultValue={this.state.account} />
                                    </div>
                                    <div className="MuiBox-root css-79elbk">
                                        <input type="number" min="0.0001" step="0.0001" name="amount" className="css-fi9125" defaultValue={0} />
                                        <p className="MuiTypography-root MuiTypography-body1 css-viowja">BNB</p>
                                    </div>
                                </div>
                                <div className="MuiBox-root css-5vb4lz"><button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-fullWidth MuiButtonBase-root Mui-disabled css-4sd5su" tabIndex="-1" type="submit" disabled="">Withdraw Amount</button></div>
                            </form>

                            <hr className="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-m7b68o">
                                <div className="MuiGrid-root MuiGrid-item css-5y52ga"><button className="MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-fullWidth MuiButtonBase-root css-u1usi" tabIndex="-1" type="button" onClick={this.seedMarket}>Start Market</button></div>
                            </div>
                        </div>
                    </div>
                 </>  
               ):(
                   <h2>Not Connect</h2>
               )}
            </>
        );
    }

}

export default Admin;